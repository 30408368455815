.App {
  text-align: left;
  background-color: #419d78;
  min-height: 100vh;
  display: flex;
}

.App-content {
  background-color: #f1f0ea;
  color: #0d0106;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 3em;
}

h1 {
  text-decoration: underline #c57b57;
}

.App-link {
  color: #145c9e;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
